<h4 mat-dialog-title> {{scheduledNotification.id ? "Edit" : "Add"}} Scheduled Notification</h4>
<mat-dialog-content>
  <form class="flex flex-col" #notificationForm="ngForm">
    <mat-form-field class="w-full pb-0">
      <mat-label>Name</mat-label>
      <input type="text" matInput [(ngModel)]="scheduledNotification.name" name="name" id="name" required maxlength="100">
    </mat-form-field>
    <mat-form-field class="w-full">
      <mat-label>Schedule</mat-label>
      <mat-select [(value)]="schedule" matInput (selectionChange)="onScheduleChange($event)">
        <mat-option [value]="scheduleEnum.daily">Daily</mat-option>
        <mat-option [value]="scheduleEnum.specificDays">Specific Days</mat-option>
      </mat-select>
    </mat-form-field>
    @if (schedule == scheduleEnum.specificDays) {
      <fieldset class="mb-3">
        <legend class="block text-sm font-medium leading-6 text-gray-900">Days</legend>
        <div class="font-sans inline-flex shadow-sm" role="group">
          <button type="button"
            (click)="sunday = !sunday"
            [ngClass]="sunday ? 'relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600' :
            'relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'">
            S
          </button>
          <button type="button"
            (click)="monday = !monday"
            [ngClass]="monday ? 'relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600' :
            'relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'">
            M
          </button>
          <button type="button"
            (click)="tuesday = !tuesday"
            [ngClass]="tuesday ? 'relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600' :
            'relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'">
            T
          </button>
          <button type="button"
            (click)="wednesday = !wednesday"
            [ngClass]="wednesday ? 'relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600' :
            'relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'">
            W
          </button>
          <button type="button"
            (click)="thursday = !thursday"
            [ngClass]="thursday ? 'relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600' :
            'relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'">
            T
          </button>
          <button type="button"
            (click)="friday = !friday"
            [ngClass]="friday ? 'relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600' :
            'relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'">
            F
          </button>
          <button type="button"
            (click)="saturday = !saturday"
            [ngClass]="saturday ? 'relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600' :
            'relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'">
            S
          </button>
        </div>
      </fieldset>
    }
    <fieldset>
      <legend class="block text-sm font-medium leading-6 text-gray-900">Time</legend>
      <div class="flex items-baseline timeSelect">
        <div class="w-8">
          @if (timeHour) {
            <mat-form-field>
              <mat-select hideSingleSelectionIndicator="true" [(value)]="timeHour" (selectionChange)="timeHourChange($event)">
                @for (item of timeHours; track item; let currentElementIndex = $index) {
                  <mat-option [value]="item">
                    {{item}}
                  </mat-option>
                }
              </mat-select>
            </mat-form-field>
          }
        </div>
        <span class="h-[67px] text-xl mr-3 z-10">:</span>
        <div class="w-8">
          <mat-form-field>
            <mat-select hideSingleSelectionIndicator="true" [(value)]="timeMinute" (selectionChange)="timeMinuteChange($event)">
              @for (item of timeMinutes; track item; let currentElementIndex = $index) {
                <mat-option [value]="item">
                  {{item}}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="w-8 ml-3">
          <mat-form-field>
            <mat-select hideSingleSelectionIndicator="true" [(value)]="timePeriod" (selectionChange)="timePeriodChange($event)">
              <mat-option value="am">AM</mat-option>
              <mat-option value="pm">PM</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </fieldset>
    @if (isFilterNotif() && hasNoDateFilter()) {
      <div class="rounded-md bg-yellow-50 p-4">
        <div class="flex">
          <div class="flex-shrink-0">
            <svg class="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
            </svg>
          </div>
          <div class="ml-3">
            <h3 class="text-sm font-medium text-yellow-800">No Date Filter</h3>
            <div class="mt-2 text-sm text-yellow-700">
              <p>It is recommended that you filter your notification by date to prevent returning all Shipments</p>
            </div>
          </div>
        </div>
      </div>
    }
    <fieldset class="mt-3">
      <legend class="block text-sm font-medium leading-6 text-gray-900">Notification Type</legend>
      <div class="isolate mt-2 -space-y-px rounded-md bg-white shadow-sm">
        @for (notificationTypeItem of notificationTypes; track notificationTypeItem; let isFirst = $first; let isLast = $last) {
          <label
            class="flex cursor-pointer border p-4 focus:outline-none"
            [ngClass]="isFirst ? 'rounded-tl-md rounded-tr-md relative' : isLast ? 'rounded-bl-md rounded-br-md relative' : '' "
            [class.z-10]="scheduledNotification.key === notificationTypeItem.Id"
            [class.border-sky-200]="scheduledNotification.key === notificationTypeItem.Id"
            [class.bg-sky-50]="scheduledNotification.key === notificationTypeItem.Id"
            [class.border-gray-200]="scheduledNotification.key !== notificationTypeItem.Id">
            <input type="radio" name="scheduledNotificationKey" [checked]="scheduledNotification.key === notificationTypeItem.Id"
              (click)="scheduledNotification.key = notificationTypeItem.Id" [value]="notificationTypeItem.Id"
              class="mt-0.5 h-4 w-4 shrink-0 cursor-pointer text-sky-600 border-gray-300 focus:ring-sky-500"
              aria-labelledby="privacy-setting-0-label" aria-describedby="privacy-setting-0-description">
              <span class="ml-3 flex flex-col">
                <span class="block text-sm font-medium"
                  [ngClass]="scheduledNotification.key === notificationTypeItem.Id ? 'text-sky-900' : 'text-gray-900'">{{
                notificationTypeItem.Name }}</span>
                <span class="block text-xs"
                  [ngClass]="scheduledNotification.key === notificationTypeItem.Id ? 'text-sky-700' : 'text-gray-500'">{{
                notificationTypeItem.Description }}</span>
                @if (isFilterNotif() && filter && filter.name) {
                  <div class="flex flex-col items-start">
                    <span class="inline-block inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 mr-1 mb-1 text-sm font-medium bg-indigo-100 text-indigo-700">
                      <span class="pr-3">{{filter.name}}</span>
                    </span>
                  </div>
                }
              </span>
            </label>
          }
        </div>
      </fieldset>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close class="dialog-footer-buttons">Cancel</button>
    @if (scheduledNotification.id) {
      <button mat-raised-button color="secondary" class="dialog-footer-buttons" (click)="confirmDeleteScheduledNotification()">Delete</button>
    }
    <button mat-raised-button color="primary" class="dialog-footer-buttons" [disabled]="!isModelValid()" (click)="saveScheduledNotification()">{{scheduledNotification.id ? "Save" : "Add"}}</button>
  </mat-dialog-actions>
  